a {
  color: inherit;
  text-decoration: none;
}

.clearfix-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.clearfix-center:after {
  content: "";
  display: table;
  clear: both;
}

.blog_list_image2 {
  max-height: 200px;
  width: auto !important;
  max-width: 80vw !important;
  border-radius: 5px;
  margin: 1rem auto !important;
}
ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

.small-horizontal-ad {
  min-width: 250px;
  max-width: 1400px;
  width: 100%;
  margin: auto;
  min-height: 90px;
  max-height: 250px;
  height: 100%;
}

.blog-image {
  max-width: 600px;
  margin: auto;
}

.blog-image-caption {
  text-align: center;
  margin: 20px auto 10px;
}

.blog-banner-image-caption {
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.container404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  padding: 24px;
  height: 80vh;
}
