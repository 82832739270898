:root {
  --font-family-primary: "Inter";
  --font-family-secondary: "Inter";
  --primary-color: #098beaff;
  --primary-color-hover: #067fd6;
  --primary-color-active: #0b73bd;
  --primary-color-faded: rgba(8, 139, 234, 0.1);
  --primary-light-color: #f9f9db;
  --primary-light-color-faded: rgba(249, 249, 219, 0.1);
  --primary-text-color: #ffffff;
  --secondary-text-color: #b7b7bb;
  --heading-color: #ffffff;
  --paragraph-color: #b7b7bb;
  --danger-text-color: #ff2c52;
  --subtitle-color: #8056b2;
  --title-color: #323339;
  --btn-gradient-bg-1: #20d462;
  --btn-bg: #8056b2;
  --btn-gradient-bg-2: #8056b2;
  --bg-color: #0c0f1e;
  --bg-secondary-color: #222533;

  --bg-secondary-color-hover: #3f424f;
  --bg-secondary-color-active: #545769;
  --bg-color-faded: rgba(12, 15, 30, 0.1);
  // --card-gradient-1: #fff7e8;
  --card-gradient-2: #fff7e8;
  --link-color: #053c34;
  --link-font-weight: 400;
  --link-decoration: none;
  --icon-color: #111;
  --input-bg-color: #e9effb;
  --error-color: #ff7c7c;
  --offer-color: #ffffff;
  --red: #fd4458;
  --card-bg-color: #1d1f36;
  --blog-title-font-size: 24px;
  --blog-heading-font-size: 20px;
  --blog-paragraph-font-size: 16px;
  --title-font-size: 24px;
  --heading-font-size: 18px;
  --default-font-size: 16px;
  --paragraph-font-size: 16px;
  --max-width: 1400px;
  --bg-modal: #353846;
  @media screen and (max-width: 768px) {
    --title-font-size: 20px;
    --heading-font-size: 18px;
    --default-font-size: 16px;
    --paragraph-font-size: 12px;
  }
}

* html {
  font-family: var(--font-family-primary);
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
