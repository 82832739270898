@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@layer base {
  :root {
    --color-primary: 9 139 234;
    --color-secondary: 34 37 51;
    --color-base: 12 15 30;
    --color-base-secondary: 34 37 51;
    --color-base-tertiary: 25 27 41;
    --color-primary-content: 255 255 255;
    --color-secondary-content: 183 183 187;
    --color-favorite: 240 82 40;
    --max-width: 1400px;
    /* ... */
  }
}

html,
body {
  color: var(--primary-text-color);
  background: var(--bg-color);

  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.shimmerFx {
  // psuedo-element shine animation left side
  &::before {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 100%;
    top: 0;
    filter: blur(30px);
    transform: translateX(-150px) skewX(-15deg);
    animation: shimmer 2s ease infinite;
  }
  // psuedo-element shine animation right side
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    transform: translateX(-150px) skewX(-15deg);
    animation: shimmer 2s ease infinite;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-150px) skewX(-15deg);
  }
  100% {
    transform: translateX(300px) skewX(-15deg);
  }
}
.clearfix-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.clearfix-center:after {
  content: "";
  display: table;
  clear: both;
}

.blog_list_image2 {
  max-height: 200px;
  width: auto !important;
  max-width: 80vw !important;
  border-radius: 5px;
  margin: 1rem auto !important;
}
ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

// .small-horizontal-ad {
//   width: 320px;
//   height: 100px;
//   margin: auto;
// }
// @media (min-width: 500px) {
//   .small-horizontal-ad {
//     width: 468px;
//     height: 60px;
//   }
// }
// @media (min-width: 800px) {
//   .small-horizontal-ad {
//     width: 728px;
//     height: 90px;
//   }
// }

.small-horizontal-ad {
  min-width: 250px;
  max-width: 1400px;
  width: 100%;
  margin: auto;
  min-height: 90px;
  max-height: 250px;
  height: 100%;
}
.big-square-ad {
  width: 100%;
  margin: auto;
  min-height: 250px;
  max-height: 350px;
  height: 100%;
}
.respAd {
  width: 100%;
  height: 100%;
  margin: auto;
  min-height: 300px;
  max-height: 400px;
  min-width: 100px;
  max-width: 1400px;
}
.mobileFirstAd {
  width: 100%;
  height: 100%;
  margin: auto;
  min-height: 250px;
  max-height: 400px;
  min-width: 100px;
  max-width: 1400px;
  @media screen and (min-width: 768px) {
    min-height: 120px;
  }
}
.desktopAd {
  display: inline-block;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.blog-image {
  max-width: 600px;
  margin: auto;
}

.blog-tableOfContent {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
}

.blog-image-caption {
  text-align: center;
  margin: 20px auto 10px;
}

.blog-highlight-text {
  position: relative;
  background-color: #191b29;
  border-radius: 10px;
  padding: 2rem 2rem 0.5rem;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    padding: 1.4rem 1.4rem 0.1rem;
  }
}

.blog-banner-image-caption {
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  // font-weight: bold;
  // margin: 20px auto 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.container404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  padding: 24px;
  height: 80vh;
}
ol {
  list-style-type: decimal;
}
ul {
  list-style-type: disc;
}
ol ol {
  list-style-type: lower-alpha;
}

.faq {
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0 !important;
  }

  p {
    margin-bottom: 0 !important;
  }
  ol li,
  ul li {
    padding-left: 0 !important;
    margin-bottom: 0 !important;
  }
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0px);
  }
}

.float-animation {
  animation: float 2s ease-in-out infinite;
}
